import React, { useRef } from 'react'
import { Button, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import { auth } from '../firebase';
import './SignIn.css'
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import firebase from 'firebase/compat/app';
const auth = firebase.auth;

const SignIn = () => {
    // 팝업 상태 저장
    const [open, setOpen] = React.useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const usehistory = useHistory();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnClick = (e) => {
        signIn(e);
      };

    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
          handleOnClick(e); // Enter 입력이 되면 클릭 이벤트 실행
        }
      };

    const signIn = async (e) => {
        e.preventDefault();

        // console.log("entersignin: "+emailRef.current.value)
        // console.log("entersignin2: "+passwordRef.current.value)

        firebase.auth().signInWithEmailAndPassword(
            emailRef.current.value,
            passwordRef.current.value
        ).then(user => {
            console.log("then move to login: " + user)
            usehistory('/');
        }).catch(err => {
            console.log("error: " + err);
            setOpen(true);
        })
    }


    const handleEmailChange = (event) => {
        const { value } = event.target;
        emailRef.current.value = value;
    };

    const handlePwChange = (event) => {
        const { value } = event.target;
        passwordRef.current.value = value;
    }

    return (
        <div className="SignIn">
            {console.log("enter signin")}
            <form>
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            홈트레이닝
                        </Typography>
                        <Typography component="h1" variant="h5">
                            사용자 로그인
                        </Typography>
                        <TextField
                            margin="normal"
                            label="이메일 주소"
                            required fullWidth
                            type="email"
                            inputRef={emailRef}
                            name="emailRef"
                            autoComplete="email"
                            onChange={handleEmailChange}
                            autoFocus sx={{ mt: 3, ml: 2, mr: 2 }} />
                        <TextField
                            margin="normal"
                            label="비밀번호"
                            name="passwordRef"
                            type="password"
                            inputRef={passwordRef}
                            onChange={handlePwChange}
                            required fullWidth
                            onKeyPress={handleOnKeyPress}
                            sx={{ mt: 3, ml: 2, mr: 2 }}></TextField>
                        {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />}
                            label="remember me" /> */}
                        <Button onClick={signIn} fullWidth variant="contained" sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>로그인</Button>
                        <Grid container>
                            <Grid item xs>
                                <Link>비밀번호재설정</Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register">회원가입</Link>
                            </Grid >
                        </Grid>
                    </Box>
                </Container>
            </form>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"로그인 오류."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        사용자 아이디와 비밀번호를 확인해 주세요.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        확 인
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default SignIn