import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArchiveIcon from "@material-ui/icons/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Button from '@mui/material/Button';

import firebase from 'firebase/compat/app';
const db = firebase.db;
const auth = firebase.auth;

const Home = () => {
  const [value, setValue] = React.useState(-1);
  const ref = React.useRef(null);
  const [listData, setListData] = React.useState([]);
  // const [messages, setMessages] = React.useState(() => refreshMessages());
  const [showlog, setShowLog] = React.useState(true);
  const collection_training = "Training";

  // React.useEffect(() => {
  //   console.log("I run only Once.");
  //   //  setMessages(refreshMessages());
  // }, []);

  // React.useEffect(() => {
  //   ref.current.ownerDocument.body.scrollTop = 0;
  //   setMessages(refreshMessages());
  //   console.log("effect호출: ");
  // }, [value, setMessages]);

  function refreshMessages() {
    setListData([]);

    try {
      db.collection(collection_training)
        .orderBy("data_total_score", "desc")
        .limitToLast(5)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            console.log(listData.length);

            setListData((listData) => [
              ...listData,
              {
                primary: "아이디: " + doc.data().userid,
                secondary: doc.data().data_total_score,
                person:
                  "https://www.tuktukdesign.com/wp-content/uploads/2021/03/person-icon.jpg",
              },
            ]);
            //    console.log(doc.data().userid+" : "+doc.data().data_total_score);
          });
        });

      //setValue(1);

      //setListData([]);
      //  console.log("haha");
      //   console.log(listData);
      if (listData.length > 0) {
        Array.from(new Set(listData));
        listData.sort((a, b) => {
          if (parseInt(a.secondary) > parseInt(b.secondary)) {
            return -1;
          } else if (parseInt(a.secondary) < parseInt(b.secondary)) {
            return 1;
          } else {
            return 0;
          }
        });
      }

      console.log(listData.length);
      return Array.from(listData);
    } catch (error) {
      console.log("error2: " + error);
    }
    //console.log(newList);
    // console.log(listData);
    // console.log(messageExamples);
    // return Array.from(listData);
  }

  return (
    <div>
      {console.log("enter Home")}
      <h1>{firebase.auth().currentUser.email} 님 환영합니다.</h1>
      <p>
        <button onClick={() => firebase.auth().signOut()}>로그아웃</button>
      </p>

      <Button variant="contained">Hello World</Button>

      
    </div>
  );
};

export default Home;
