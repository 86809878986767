import { React, useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArchiveIcon from "@material-ui/icons/Archive";

// import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Button from '@mui/material/Button';
import firebase from 'firebase/compat/app';
//import ApexCharts from 'apexcharts';
import Chart from "react-apexcharts"; 
import Typography from '@mui/material/Typography';

const TrainingGraph = () => {

    const series = [
        {
          name: "운동시간(분)",
          data: [19, 26, 22, 24]
        }
      ];
      const options = {
        xaxis: {
          categories: ["2020-11-18", "2021-05-02", "2022-05-03", "2023-01-18"]
        }
      };

    return (
        <div id="chart">
             <Typography component="h1" variant="h5">
        그래프 페이지
      </Typography>
      <Chart type="line" series={series} options={options} />
        </div>
    );
}
export default TrainingGraph;