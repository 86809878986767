import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled, useTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MuiDrawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Typography,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AddCircle from "@material-ui/icons/AddCircle";
import Send from "@material-ui/icons/Send";

import ChartIcon from '@material-ui/icons/ChatBubble';

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import firebase from "firebase/compat/app";
import Trainer from "./Trainer";
import AddTrainer from "./AddTrainner";
import Home from "./Home";
import TopRank from "./TopRank";
import SendPush from "./SendPush";
import TrainGraph from "./TrainingGraph";
//import { Send } from "@material-ui/icons";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const useStyles = makeStyles((theme) => ({
  drawerPaper: { width: "inherit" },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Main() {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = React.useState(0);

  const changepage = (idx) => {
    setPage(idx);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogIn = (event) => {
    setAuth(true);
    setAnchorEl(false);
  };

  const handleLogout = () => {
    setAuth(false);
    firebase.auth().signOut();
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function setComponent(idx) {
    console.log(idx);
    if (idx === 0) {
      return { Home };
    } else if (idx === 1) {
      return { TopRank };
    } else if (idx === 2) {
      return { Trainer };
    } else if (idx === 3) {
      return { Trainer };
    } else if (idx === 4) {
      return { Trainer };
    }
    return { Home };
  }

  return (
    <Router>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={auth}
                onChange={handleChange}
                aria-label="login switch"
              />
            }
            label={auth ? "Logout" : "Login"}
          />
        </FormGroup>
        <CssBaseline />
        <Box sx={{ flexGrow: 1 , justifyContent: "center" }}>
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                HomeTraining
              </Typography>

              {!auth && (
                <Box
                  sx={{ display: "flex", justifyContent: "center", width: 1 }}
                >
                  <Button color="inherit" onClick={handleLogIn}>
                    Login
                  </Button>
                </Box>
              )}

              {auth && (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", width: 1 }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              )}
            </Toolbar>
          </AppBar>
        </Box>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <List>
            <ListItem button onClick={() => changepage(0)}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
            <ListItem button onClick={() => changepage(1)}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={"랭킹순위"} />
            </ListItem>
            <ListItem button onClick={() => changepage(2)}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"트레이너"} />
            </ListItem>
            <ListItem button onClick={() => changepage(3)}>
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>
              <ListItemText primary={"트레이너등록"} />
            </ListItem>
            <ListItem button onClick={() => changepage(5)}>
              <ListItemIcon>
                <ChartIcon />
              </ListItemIcon>
              <ListItemText primary={"운동그래프"} />
            </ListItem>
            <ListItem button onClick={() => changepage(4)}>
              <ListItemIcon>
                <Send />
              </ListItemIcon>
              <ListItemText primary={"Push전송"} />
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ display: "flex-start", flexGrow: 5, p: 3 , justifyContent: "center" }}>
          <DrawerHeader />
          {page === 0 ? <Home /> : <div></div>}
          {page === 1 ? <TopRank /> : <div></div>}
          {page === 2 ? <Trainer /> : <div></div>}
          {page === 3 ? <AddTrainer /> : <div></div>}
          {page === 4 ? <SendPush /> : <div></div>}
          {page === 5 ? <TrainGraph /> : <div></div>}
          
        </Box>
      </Box>
    </Router>
  );
}

export default Main;
