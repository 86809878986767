import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArchiveIcon from "@material-ui/icons/Archive";

// import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import firebase from "firebase/compat/app";
import Container from "@mui/material/Container";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  rowcontainer1: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowcontainer2: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    borderColor: "#eee",
    borderBottomWidth: 10.5,
  },
  colcontainer1: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
    paddingLeft: 10,
  },
}));

const AddTrainer = () => {
  const classes = useStyles();

  const registTraniner = async(e) => {
    e.preventDefault();
   
   // console.log("entersignin: "+emailRef.current.value)
   // console.log("entersignin2: "+passwordRef.current.value)

    // auth.signInWithEmailAndPassword(
    //     emailRef.current.value,
    //     passwordRef.current.value
    // ).then(user => {
    //     console.log("then move to login: "+user)
    //     navigate('/');
    // }).catch(err => {
    //     console.log("error: "+err);
    //     setOpen(true);
    // })
}


  return (
    <div>
      <form>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography component="h1" variant="h5">
              트레이너 등록 페이지
            </Typography>

            <TextField
              margin="normal"
              id="outlined-helperText"
              label="트레이너 이름"
              type="search"
              variant="outlined"
            />
            <TextField
              margin="normal"
              id="outlined-helperText"
              label="Search field"
              type="search"
              variant="outlined"
            />
            <TextField
              margin="normal"
              id="outlined-helperText"
              label="Helper text"
              variant="outlined"
            />

            <Button
              margin="normal"
              onClick={registTraniner}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, ml: 0, mr: 0 }}
            >
              등록하기
            </Button>
          </Box>
        </Container>
      </form>
      <Container component="main" maxWidth="xs">
      <Button
              onClick={registTraniner}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, ml: 0, mr: 0 }}
            >
              사용자 조회
            </Button>
      </Container>
    </div>
  );
};
export default AddTrainer;
