import React, { useRef } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from "react-router-dom";
import './SignUp.css'
import firebase from 'firebase/compat/app';
const auth = firebase.auth;

const Signup = () => {
  const [checked, setChecked] = React.useState(true);

  const [openDialog, setOpenDialog] = React.useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const usehistory = useHistory();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    usehistory("/login");
  };

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
          HomeTraining
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const theme = createTheme();

  const signUp = e => {
    e.preventDefault();

    //allowExtraEmails

    auth.createUserWithEmailAndPassword(
      emailRef.current.value,
      passwordRef.current.value
    ).then(user => {
      console.log("user: " + user);
      if (user.id) {
        console.log("uid: " + user.id);
        setOpen(true);
      } else {
        setOpenDialog(true);
      }

    }).catch(err => {
      console.log(err);
      setOpenDialog(true);
    })
  }

  const handleEmailChange = (event) => {
    const { value } = event.target;
    emailRef.current.value = value;
  };

  const handlePwChange = (event) => {
    const { value } = event.target;
    passwordRef.current.value = value;
  }

  return (
    <div className="signup">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              회원가입
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="이 름"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="이메일주소"
                    name="email"
                    autoComplete="email"
                    onChange={handleEmailChange}
                    inputRef={emailRef}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="비밀번호"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    inputRef={passwordRef}
                    onChange={handlePwChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="회원가입에 따른 마케팅 정보 및 사용자 정보 제공에 동의합니다."
                  />
                </Grid>
              </Grid>
              <Button color="secondary"
                type="submit"
                fullWidth
                variant="contained"
                onClick={signUp}
                sx={{ mt: 3, mb: 2 }}
              >
                회원가입
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    계정이 있습니까? 로그인이동
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"회원가입완료"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>확 인</Button>
            </DialogActions>
          </Dialog>

        </Container>
      </ThemeProvider>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"회원가입 오류."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            사용자 아이디와 비밀번호를 확인해 주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            확 인
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}
export default Signup;