import { useEffect, useState } from "react";
import "./App.css";
import Home from "./components/pages/Home";
import SignIn from "./components/pages/auth/SignIn";
import Signup from "./components/pages/auth/SignUp";
import Main from "./components/pages/Main";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { auth } from './firebase';

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      const user = {
        uid: userAuth?.uid,
        email: userAuth?.email,
      };
      if (userAuth) {
        console.log(userAuth);
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
      {console.log("enter App")}
      {console.log(user)}
        <Switch>
          <Route exact path="/" component={user ? Main : SignIn}  />
          <Route path="/login" component={SignIn} />
          <Route path="/register" component={Signup} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
export default App;