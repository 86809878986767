import { React, useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { collection, query, where, getDocs } from "firebase/firestore";
import Const from "../Utils/Constants";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';

const db = firebase.db;
const auth = firebase.auth;

const TopRank = () => {
  const [listData, setListData] = useState([]);
  const ref = useRef(null);
  const [usersLoading, setUsersLoading] = useState(false);

  const collection_training = "Training";

  const fetchTrainingData = () => {
    try {
      setListData([]);
      firebase
        .firestore()
        .collection(collection_training)
        .orderBy("data_total_score", "desc")
        // .where("data_total_score", ">=", 0)
        .limit(5)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            console.log(listData.length);
            setListData((listData) => [
              ...listData,
              {
                primary: "아이디: " + doc.data().userid,
                secondary: doc.data().data_total_score,
                person:
                  "https://www.tuktukdesign.com/wp-content/uploads/2021/03/person-icon.jpg",
              },
            ]);
          });
        })
        .catch((error) => {
          if (Const.enablelog) {
            console.log("Error getting documents: ", error);
          }
        });

      if (listData.length > 0) {
        if (Const.enablelog) {
          console.log(listData.length);
        }
        return Array.from(listData);
      }
    } catch (error) {
      if (Const.enablelog) {
        console.log("error2: " + error);
      }
    }
  };

  useEffect(() => {
    // wrap your async call here
    const loadData = async () => {
      setUsersLoading(true);
      const result = await fetchTrainingData();
      //  setDatas(result);
      console.log(result);
      setUsersLoading(false);
    };

    try {
      loadData();
    } catch (err) {
      console.log(err);
      console.log("fetching error");
    }
    // then call it here
  }, []);

  return (
    <div>
      <Typography component="h1" variant="h5">
        탑랭크 페이지
      </Typography>

      {console.log("enter TopRankPage")}
      <Container component="main" maxWidth="xs">
        <Box sx={{ justifyContent: "center" }} ref={ref}>
          <CssBaseline />
          {listData ? (
            <List>
              {listData.map(({ primary, secondary, person }, index) => (
                <ListItem button key={index} alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Profile Picture" src={person} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={primary}
                    secondary={"운동점수: " + secondary}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <br></br>
          )}
        </Box>
      </Container>
    </div>
  );
};
export default TopRank;
