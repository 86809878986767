import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBSJVZHp7CWOrfADOIGtLUtP8RYD5ZdG3A",
  authDomain: "homtraining.firebaseapp.com",
  databaseURL: "https://homtraining-default-rtdb.firebaseio.com",
  projectId: "homtraining",
  storageBucket: "homtraining.appspot.com",
  messagingSenderId: "118261406113",
  appId: "1:118261406113:web:dd75198d817c30fb0d53e0",
  measurementId: "G-HWX1CEGKK7"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore()